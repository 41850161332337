export enum ECountry {
    SE = 'SE',
    DE = 'DE',
    NO = 'NO',
    UK = 'UK',
    AT = 'AT',
}

export enum ELocale {
    SV = 'sv-SE',
    EN = 'en-GB',
    NO = 'no-NO',
    DE = 'de-DE',
    MASTER = 'master',
}

export enum ELanguage {
    'sv-SE' = 'Svenska',
    'en-GB' = 'English',
    'no-NO' = 'Norsk',
    'de-DE' = 'Deutsch',
    'master' = 'Master',
}

export enum ECurrency {
    EUR = 'EUR',
    SEK = 'SEK',
    NOK = 'NOK',
    GBP = 'GBP',
}

export enum ButtonVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TEXT = 'text',
    GREY = 'grey',
}

export enum ButtonRadius {
    ROUNDED = 'rounded',
}

export enum ButtonSize {
    DEFAULT = 'default',
    SMALL = 'small',
}

export enum ButtonType {
    SUBMIT = 'submit',
    BUTTON = 'button',
    RESET = 'reset',
}

export type Address = {
    id?: string;
    country?: string;
    region?: string;
    city: string;
    postalCode: string;
    addressLine1: string;
    addressLine2?: string;
};

export enum EToastType {
    ERROR = 'error',
    SUCCESS = 'success',
}

export type ConsentFormType = {
    termsAndConditionsConsent: boolean;
    gdprConsent: boolean;
};

export enum EPageErrorType {
    TEMPORARY = 'temporary',
    CRITICAL = 'critical',
    BROKEN_URL = 'brokenUrl',
}
