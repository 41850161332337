import './i18n';
import './index.css';

import { BrowserRouter } from 'react-router-dom';
import { ErrorProvider } from 'contexts/ErrorContext';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastProvider } from 'contexts/Toast';
import { pca } from 'hooks/useAuthentication';
import AppRoutes from 'AppRoutes';
import ReactGA from 'react-ga4';
import ToastsWrapper from 'components/info/ToastsWrapper';

const trackingArgs = {
    trackingId: import.meta.env.VITE_APP_GOOGLE_ANALYTICS4_ID || 'missing-google-analytics4-id',
    gtagOptions: { debug_mode: import.meta.env.VITE_APP_GOOGLE_ANALYTICS4_DEBUG || false },
};

ReactGA.initialize([trackingArgs]);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
            retry: false,
        },
    },
});

function App(): JSX.Element {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <ToastProvider>
                    <ErrorProvider>
                        <MsalProvider instance={pca}>
                            <AppRoutes />
                            <ToastsWrapper />
                        </MsalProvider>
                    </ErrorProvider>
                </ToastProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
}

export default App;
