import {
    colorBlue,
    colorNeutralsWhite,
    colorPrimaryMerBlue,
    font,
    linkHoverBackgound,
    readingWidth,
    screenWidthSmall,
    spaceL,
    spaceM,
    transitionSnappy,
} from 'styles/variables';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    // Fix for iPhone X
    padding: env(safe-area-inset);
  }

  body {
    background-color: ${colorNeutralsWhite};
    color: ${font.color.default};
    font-size:${font.size.m};
    font-family: ${font.body};
    font-weight: ${font.weight.regular};
    -webkit-font-smoothing: antialiased;

    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }

  .ReactModal__Overlay--after-open {
    z-index: 9999;
    overflow-y: auto;
  }

  h1,
  h2 {
    font-weight: 700;
  }
  
  h3,
  h4 {
    font-weight: normal;
  }

  h1,
  .styled-h1 {
    margin-bottom: ${spaceM};
    font-family: 'SharpSans Bold', sans-serif;
    font-size: ${font.size.xl};
    line-height: ${font.lineHeight.xl};

    @media screen and (min-width: ${screenWidthSmall}) {
      font-size: ${font.size.xxl};
      line-height: ${font.lineHeight.xxxl};
      margin-bottom: ${spaceL};
    }
  }

  h2 {
    margin-bottom: 0.5rem;
    font-size: ${font.size.xl};
    font-family: 'SharpSans Bold', sans-serif;
  }

  h3,
  h2.styled-h3 {
    margin-bottom: 1rem;
    font-size: ${font.size.l};
    line-height: ${font.size.l};
    font-family: 'SharpSans Semibold', sans-serif;
  }

  h4 {
    font-size: ${font.size.m};
  }

  ul {
    list-style:none;
  }

  p {
    margin-bottom: 1rem;
    line-height: ${font.lineHeight.l};
  }

  a {
    color: ${colorPrimaryMerBlue};
    transition: all ${transitionSnappy};

    &:hover {
      background-color: ${linkHoverBackgound};
    }

    &:focus {
      outline: 4px solid ${colorBlue};
    }
  }

  button{
    cursor:pointer;
    &:disabled{
      cursor:not-allowed;
    }
  }

  strong {
    font-weight: normal;
    font-family: 'SharpSans Semibold', sans-serif;
  }

  .reading-max-width {
    max-width: ${readingWidth};
  }
  
  a.button-link {
    text-decoration: none;
  }

  .link-button {
    text-decoration: none
  }
  
`;

export default GlobalStyle;
