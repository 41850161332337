import { ELocale, EPageErrorType } from 'types';
import { PATH } from 'constants/paths';
import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import { useLanguage } from 'hooks/useLanguage';
import RouteWrapper from 'components/wrappers/RouteWrapper';
import i18n from 'i18n';

const DeliveryAddress = lazy(() => import('pages/DeliveryAddressPage'));
const DeliveryAddressNotifications = lazy(() => import('pages/DeliveryAddressNotifications'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));

function AppRoutes(): JSX.Element {
    const { getValidBULanguage } = useLanguage();
    i18n.changeLanguage(getValidBULanguage(i18n.language as ELocale));

    return (
        <Routes>
            {import.meta.env.VITE_APP_ENV !== 'live' && <Route path="/noauth">noauth</Route>}
            <Route element={<RouteWrapper />}>
                <Route path={PATH.WILDCARD} element={<ErrorPage type={EPageErrorType.BROKEN_URL} />} />
                <Route path={PATH.ERROR} element={<ErrorPage />} />
                <Route path={PATH.CRITICAL_ERROR} element={<ErrorPage type={EPageErrorType.CRITICAL} />} />
                <Route path={PATH.TEMPORARY_ERROR} element={<ErrorPage home refresh />} />
                <Route path={PATH.PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS} element={<DeliveryAddress />} />
                <Route
                    path={PATH.PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS_STATUS}
                    element={<DeliveryAddressNotifications />}
                />
                <Route
                    path={PATH.PUBLIC_SUBSCRIPTIONS_WILDCARD}
                    element={<ErrorPage type={EPageErrorType.BROKEN_URL} />}
                />
                <Route path={PATH.PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS} element={<DeliveryAddress />} />
                <Route
                    path={PATH.PUBLIC_SUBSCRIPTIONS_DELIVERY_ADDRESS_STATUS}
                    element={<DeliveryAddressNotifications />}
                />
            </Route>
        </Routes>
    );
}

export default AppRoutes;
