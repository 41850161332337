import { ELocale, EToastType } from 'types';
import { ETrackingEvent } from 'types/tracking';
import {
    borderRadiusS,
    colorLighterBlue,
    colorNeutralsWhite,
    colorPrimaryMerBlue,
    font,
    menuBoxShadow,
    spaceM,
    spaceS,
    spaceXs,
} from 'styles/variables';
import { useLanguage } from 'hooks/useLanguage';
import { useMemo, useState } from 'react';
import { useToast } from 'contexts/Toast';
import Arrow from 'assets/icons/chevron.svg?react';
import Checkmark from 'assets/icons/check.svg?react';
import ReactGA from 'react-ga4';
import i18n from 'i18n';
import styled from 'styled-components';

const Flag = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    svg {
        width: inherit;
        height: inherit;
    }
`;

const Container = styled.div`
    margin-left: auto;
    min-width: 13rem;
    position: relative;
    text-align: left;
    margin-right: ${spaceS};

    .select-button {
        height: 2.75rem;
        border: none;
        background: none;
        color: ${font.color.grey};
        border-radius: ${borderRadiusS};
        font-size: ${font.size.s};
        padding: 0 ${spaceM};
        display: flex;
        align-items: center;
        gap: ${spaceXs};
        &:hover {
            background: ${colorLighterBlue};
        }
        .arrow {
            margin-left: ${spaceXs};
            &.open {
                transform: rotate(180deg);
            }
        }
    }
    .select-dropdown {
        min-width: 13rem;
        border-radius: ${borderRadiusS};
        box-shadow: ${menuBoxShadow};
        position: absolute;
        margin-top: 0.625rem;
        z-index: 1;
        overflow: hidden;
        font-size: ${font.size.s};
        color: ${font.color.grey};
        background: ${colorNeutralsWhite};

        .language {
            height: 3rem;
            &[aria-selected='true'] {
                background: ${colorLighterBlue};
                color: ${colorPrimaryMerBlue};
            }
            &:hover {
                background: ${colorLighterBlue};
            }
            button {
                padding: 0 ${spaceS};
                margin: 0;
                height: inherit;
                background: none;
                border: none;
                width: 100%;
                text-align: left;
                color: inherit;
                display: grid;
                grid-template-columns: minmax(0, 1rem) 1fr 1rem;
                align-items: center;
                gap: ${spaceXs};
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
`;

function LanguageSelector(): JSX.Element {
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const { addToast } = useToast();

    const { getLanguageList, getValidLanguage } = useLanguage();
    const languagelist = useMemo(() => getLanguageList(), [getLanguageList]);

    const activeLanguage = getValidLanguage((localStorage.getItem('locale') as ELocale) ?? ELocale.EN);

    const handleChangeLanguage = (language: ELocale): void => {
        i18n.changeLanguage(language).catch((err) => addToast({ message: err, type: EToastType.ERROR }));
        setDropDownOpen(false);
        ReactGA.event(ETrackingEvent.CHANGE_LANGUAGE, {
            label: language,
        });
    };

    return (
        <Container>
            <div className="dropdown">
                <button
                    data-testid="languageSelector"
                    type="button"
                    className="select-button"
                    onClick={() => setDropDownOpen(!dropDownOpen)}
                    role="combobox"
                    aria-haspopup="listbox"
                    aria-controls="language-dropdown"
                    aria-expanded={dropDownOpen}
                    aria-activedescendant={`language_${activeLanguage?.value}`}
                >
                    <Flag>{activeLanguage?.flag}</Flag> {activeLanguage?.name}
                    <Arrow className={`arrow ${dropDownOpen && 'open'}`} />
                </button>
                {dropDownOpen && (
                    <ul
                        className="select-dropdown"
                        role="listbox"
                        id="language-dropdown"
                        tabIndex={-1}
                        data-testid="languageList"
                    >
                        {languagelist.map(({ name, value, flag }) => (
                            <li
                                key={value}
                                data-testid={`language_${value}`}
                                id={`language-${value}`}
                                aria-selected={activeLanguage?.value === value}
                                role="option"
                                className="language"
                            >
                                <button type="button" onClick={() => handleChangeLanguage(value)}>
                                    <div>{flag}</div> <span>{name}</span>{' '}
                                    {activeLanguage?.value === value && <Checkmark />}
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </Container>
    );
}

export default LanguageSelector;
