import { BuType, UseBUContentResult } from 'types/businessUnit';
import { REACT_QUERY_ONE_CALL_OPTIONS } from 'constants/general';
import { getCountryFromUrl } from 'utils/url';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const emptyBUContent: BuType = {
    privacyPolicyLink: '',
    termsOfSalesLink: '',
    availableLanguages: [],
};

const useBUContent = (): UseBUContentResult => {
    const bu = getCountryFromUrl();
    const buFile = `/bu_${bu}.json`;

    const fetcher = async (): Promise<BuType> => {
        const result = await axios.get<BuType>(buFile).then((resp) => resp.data);

        return result;
    };

    const { data: buContent } = useQuery<BuType>([buFile], fetcher, REACT_QUERY_ONE_CALL_OPTIONS);

    return {
        buContent: buContent ?? emptyBUContent,
    };
};

export default useBUContent;
