/* eslint-disable react/jsx-no-useless-fragment */
import { InteractionStatus } from '@azure/msal-browser';
import { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import useAuthentication from 'hooks/useAuthentication';

type Props = {
    children: React.ReactNode;
};

function PublicRoute({ children }: Props): JSX.Element {
    const { inProgress, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { loginRedirectAnonymous } = useAuthentication();

    useEffect(() => {
        if (!isAuthenticated && inProgress === InteractionStatus.None && accounts.length === 0) {
            loginRedirectAnonymous();
        }
    }, [isAuthenticated, inProgress, loginRedirectAnonymous, accounts]);

    if (isAuthenticated && inProgress === InteractionStatus.None) {
        return <>{children}</>;
    }

    return <></>;
}

export default PublicRoute;
